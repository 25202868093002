// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-comics-maddog-1-js": () => import("./../src/pages/comics/maddog1.js" /* webpackChunkName: "component---src-pages-comics-maddog-1-js" */),
  "component---src-pages-comics-maddog-2-js": () => import("./../src/pages/comics/maddog2.js" /* webpackChunkName: "component---src-pages-comics-maddog-2-js" */),
  "component---src-pages-comics-maddog-3-js": () => import("./../src/pages/comics/maddog3.js" /* webpackChunkName: "component---src-pages-comics-maddog-3-js" */),
  "component---src-pages-comics-maddog-4-js": () => import("./../src/pages/comics/maddog4.js" /* webpackChunkName: "component---src-pages-comics-maddog-4-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */)
}

